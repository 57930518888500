
export default {
  props: ["img", "root"],
  data() {
    return {
      lang: this.$router.currentRoute.params.lang,
    };
  },
  methods: {
    ToPage(url) {
      this.$router.push(`/${this.lang}/${this.root}` + url);
      console.log(this.$router.currentRoute);
    },
  },
};
